import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@layout";
import { Grid, Title, Box, Flex, Col, Button, Text, Modal } from "@components";


import AdminContext from "@context";
import { getBlog } from "@services";
import { navigate } from "gatsby";

const RegistroPage = ({ blogId }) => {
	const {
		auth: { token, uuid },
	} = useContext(AdminContext);

	const [blog, setBlog] = useState(null);

	useEffect(() => {
		getBlog(blogId, token).then((data) => {
			setBlog(data);
			console.log(data);
		});
	}, []);

	const handleChange = () => {};

	return (
		<Layout>
			{!!blog && (
				<Flex>
					<Col md={12}>
						<Grid className="pv:2">
							<Title className="mb:1">Name property</Title>
						</Grid>
					</Col>
				</Flex>
			)}
		</Layout>
	);
};

export default RegistroPage;
